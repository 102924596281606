// @ts-check
import { html } from 'lit-html'
import { stripRenderedText } from './../Utils'
/**
 * costruisce la visualizzazione di un elemento nel listato dei risultati della ricerca
 * @param {Object} data i dati del post
 */
export const searchResultItem = (data) => {
  return html`
      <li class="result-item">
        <a href="${data.link}">${stripRenderedText(data.title.rendered)}</a>
      </li>
    `
}
