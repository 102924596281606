// @ts-check
import { render, html } from "lit-html"
import { searchResultItem } from "./../templates/searchResultItem"
/**
 * costruisce la visualizzazione di un elemento nel listato dei risultati della ricerca
 * @param {Object} results i dati ritornati dal service
 *
 */
 export function searchResults (results) {
    const resultPanel = document.getElementById('results-content')
    const panel = document.getElementById('results-panel')
    panel.classList.remove('hide')
    if (results.data.length > 0) {
      render(html`${results.data.map((item) => searchResultItem(item))}`, resultPanel)
    } else {
      render(html`<div>Nessun elemento trovato</div>`, resultPanel)
    }
  }