/**
 * estrae i parametri ed i valori da una stringa formattata tip url ad es api?=pippo=pluto&paperino=false
 * @param {String} url
 * @returns Object
 */
export function getUrlParams (url) {
  var vars = {}
  // eslint-disable-next-line no-unused-vars
  var parts = url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value
  })
  return vars
}

/**
 * richiede o invia dati alle api
 * @param {*} method
 * @param {*} url
 */
export async function fetchApiData (method = 'get', url) {
  let totalPages, totalItems
  return new Promise(function (resolve, reject) {
    // eslint-disable-next-line no-undef
    fetch(url, {
      method: method,
      credentials: 'same-origin',

    }).then((response) => {
      if (!response.ok) {
        reject(new Error('Not 200 response'))
      }

      totalItems = response.headers.get('X-WP-Total')
      totalPages = response.headers.get('X-WP-TotalPages')

      return response.json()
    }).then((json) => {
      resolve({
        data: json,
        params: {
          total: totalItems,
          pages: totalPages,
          ...getUrlParams(url)
        }
      })
    }).catch(er => {
      reject(er)
    })
  })
}
