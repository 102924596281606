// @ts-check
import { fetchApiData } from './../libs/wp/Api'
import { EndPoints } from '../EndPoints'
import { searchResults } from './../elementsfactory/searchResults'
import { loadingSearch } from './../elementsfactory/loadingSearch'
import { getCurrentCategoryId } from './../libs/wp/WPUtils.js'
/**
 * inizializza il menu mobile(apertura e chiusura)
 */
let menuInited = false
export function mobileMenu () {
  if (!menuInited) {
    console.log('mobileMenu')
    menuInited = true
    const toggle = document.querySelector('#menuToggle input')
    const mobileMenuPanel = document.querySelector('.mobile-menu')
    const html = document.documentElement
    if (toggle && mobileMenuPanel) {
      toggle.addEventListener('change', (e) => {
      // @ts-ignore
        if (e.target.checked) {
          mobileMenuPanel.classList.add('menu-open')
          html.classList.add('no-v-scroll')
        } else {
          mobileMenuPanel.classList.remove('menu-open')
          html.classList.remove('no-v-scroll')
        }
      })
      initSearchUi()
    }

    const pagesContainer = document.querySelector('.pages-container')
    if (pagesContainer) {
    // @ts-ignore
      const categoryId = window.PageData.is_single
        ? pagesContainer.getAttribute('data-category')
        : getCurrentCategoryId()
      handleCategoriesMenu(categoryId)
    }
  }
}

let searchInited = false
/**
 * inizializza e gestisce il search nel menu
 */
function initSearchUi () {
  console.log('initSearchUi')
  if (!searchInited) {
    searchInited = true
    const btnSearch = document.getElementById('search-btn')
    const btnEmptySearch = document.getElementById('close-results')
    const input = document.getElementById('search')
    const panel = document.getElementById('results-panel')

    if (btnSearch && btnEmptySearch && input && panel) {
      btnSearch.addEventListener('click', async (e) => {
        console.log('searchClick')
        // @ts-ignore
        const url = EndPoints.POSTS + '?per_page=100&search=' + input.value
        loadingSearch()
        const result = await fetchApiData('get', url)
        searchResults(result)
      })
      btnEmptySearch.addEventListener('click', async (e) => {
        panel.classList.add('hide')
      })
    }
  }
}

/**
 * applica la classe link-selected all'elemento selezionato (rimuovendola da tutti gli altri) e porta tale elemento all'interno del viewport
 * @param {Array} linkList Array di link nel menu
 * @param {Element} listElement l'elemento selezionato
 */
export function selectMenuItem (linkList, listElement) {
  if (listElement !== undefined) {
    const parent = listElement.parentNode
    const id = listElement.getAttribute('data-taxonomy-id')
    // @ts-ignore
    parent.classList.add('link-selected')
    setTimeout(() => {
      // @ts-ignore
      parent.scrollIntoView({ block: 'start', inline: 'start', behavior: 'smooth' })
    }, 20)

    // @ts-ignore
    const others = linkList.filter((el) => el.querySelector('a').getAttribute('data-taxonomy-id') !== parent.querySelector('[data-taxonomy-id="' + id + '"]').getAttribute('data-taxonomy-id'))
    others.forEach((el) => {
      el.classList.remove('link-selected')
    })
  }
}
/**
 * rimuove da tutti gli elementi la classe link-selected (se presente)
 * @param {Array} linkList Array di link nel menu
 */
export function deselectAllMenuItems (linkList) {
  linkList.forEach((el) => {
    el.classList.remove('link-selected')
  })
}
/**
 * inizializza il menu
 */
export function initMenu () {
  mobileMenu()
}

/**
 * si occupa di lanciare la selezione del menu categorie
 * @param {*} categoryId
 */
export function handleCategoriesMenu (categoryId) {
  let listElement = document.querySelector('[data-taxonomy-id="' + categoryId + '"]')
  if (listElement == null) {
    const children = Array.from(document.querySelectorAll('[data-taxonomy-children]'))
    // @ts-ignore
    listElement = children.filter(c => c.getAttribute('data-taxonomy-children').indexOf(categoryId) !== -1)[0]
  }
  if (listElement !== null) {
    selectMenuItem(Array.from(document.querySelectorAll('.categories-menu ul li')), listElement)
  }
}
