// @ts-check
// @ts-ignore
// eslint-disable-next-line no-unused-vars
import style from './main.css'
// import { init } from './js/app.js'
import { loadImage } from './js/libs/LazyLoad'
import { mobileMenu } from './js/elements/menu'
/**
 * PageData è una variabile globale passata da wp_localize_script
 */
/* const application = app */

/**
 * initializeAll -> fa partire la baracca
 *
 * @param {Event | undefined } e :Event
 */

const initializeAll = function (e = undefined) {
  // window.performance.mark('initialize');

  document.removeEventListener('DOMContentLoaded', initializeAll)

  // window.PageData viene passato da wp tramite il localize_script di wordpress

  mobileMenu()
  // ascolto la prima interazione
  document.body.addEventListener('touchstart', initInteractions)
  window.addEventListener('scroll', initInteractions)
  checkForceLoadFirstImage()
  startReloadTimer()
}

function reloadPage () {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  if (urlParams.has('refresh')) {
    window.location.reload()
  } else {
    let i = 0
    urlParams.forEach((p, idx) => i++)
    if (i === 0) {
      window.location.href = window.location.href + '?refresh'
    } else {
      window.location.href = window.location.href + '&refresh'
    }
  }
}

function startReloadTimer () {
  let live = false
  // @ts-ignore
  const twitchLive = window.twitchLive
  // @ts-ignore
  const currentData = window.PageData
  const splitted = currentData.template.split('/')
  const type = splitted.pop()
  if (twitchLive) {
    live = twitchLive.isLive.live
  }
  if (live || type === 'live.php') {
    const fiveMinutes = 60 * 5 * 1000
    setTimeout(() => {
      reloadPage()
    }, fiveMinutes)
  }
}

/**
 * Controlla se deve forzare il load della prima immagine
 *
 * se non esiste l' attributo loading nei tag img
 * - > non è supportato il lazyload nativo
 * -> il php aggiunge l'attributo data-src
 * ->forzo il primo load di immagine
 */
function checkForceLoadFirstImage () {
  const firstImage = document.querySelector('.slide-image-container img') !== null
    ? document.querySelector('.slide-image-container img')
    : document.querySelector('.image-container img')

  if (firstImage !== null && firstImage.hasAttribute('data-src')) {
    loadImage(firstImage)
  }
}

let inited = false
/**
 * Alla prima interazione (o al reload della pagina già scrollata)
 * carica app.js e inizializza l'applicazione
 *
 * @param {Event} e
 */
async function initInteractions (e) {
  if (!inited) {
    inited = true
    document.body.removeEventListener('touchstart', initInteractions)
    window.removeEventListener('scroll', initInteractions)
    // la prima immagine della view
    const { init } = await import('./js/app')

    // @ts-ignore
    await init(window.PageData)
  }
}

if (document.readyState === 'complete' || document.readyState === 'interactive') {
  initializeAll()
} else {
  document.addEventListener('DOMContentLoaded', initializeAll)
}
