
/**
 * controlla se la pagina corrente è la homepage
 * @returns Boolean
 */
function isHomePage () {
  return document.body.classList.contains('home')
}
export { isHomePage }

/**
 * recupera l'id di categoria,  tassonomia o  tag del loop corrente
 * @returns Int
 */
function getCurrentCategoryId () {
  const bodyClass = Array.from(document.body.classList)
  for (let i = 0; i < bodyClass.length; i++) {
    const splitted = bodyClass[i].split('-')
    if ((splitted[0] === 'category' || splitted[0] === 'term' || splitted[0] === 'tag'|| splitted[0] === 'video_category') && !isNaN(parseInt(splitted[1]))) {
      return parseInt(splitted[1])
    }
  }
  return 0
}
export { getCurrentCategoryId }
