// @ts-check
import { render, html } from "lit-html"
export function loadingSearch () {
  const resultPanel = document.getElementById('results-content')
  const panel = document.getElementById('results-panel')
  if(panel && resultPanel) {
    panel.classList.remove('hide')
    render(
      html`
        <div class="loading-search"><span>loading...</span></div>
      `,
      resultPanel
    )
  }
}