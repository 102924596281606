// @ts-check
/* eslint-disable no-undef */
/* eslint-disable no-useless-escape */
/**
 * Created by zp on 12/06/18.
*/

/**
 * crea un tag html con classe (opzionale) e relativo nodo di testo (opzionale)
 * @param { String } type : la tipologia del tag
 * @param { String } cls : una stringa di classi separate da uno spazio
 * @param { String } txt : il testo del nodo testuale
*/
export function createElementWithClass (type, cls = '', txt = '') {
  const element = document.createElement(type)
  if (cls !== undefined)element.className = cls
  if (txt !== undefined)element.appendChild(document.createTextNode(txt))
  return element
}

/**
 * ritorna il valore di scrolling attuale della finestra
*/
export function getCurrentScroll () {
  return window.pageYOffset || document.documentElement.scrollTop
}

/**
 * ritorna la larghezza attuale della finestra
*/
export function getWindowWidth () {
  return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
}
/**
 * ritorna l'altezza attuale della finestra
*/
export function getWindowHeight () {
  return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
}

/**
 * svuota completamente un nodo
 * @param { HTMLElement } node : il nodo da svuotare
 */
export function emptyNode (node) {
  while (node.firstChild) {
    node.firstChild.remove()
  }
}

/**
 * carica uno script da un url
 * @param {String} url
 */
export function fetchScript (url) {
  return new Promise(function (resolve, reject) {
    const script = document.createElement('script')
    document.body.appendChild(script)
    script.onload = resolve
    script.onerror = reject
    script.async = true
    script.src = url
  })
}

/**
 * @todo : descrivere cosa fa
 * @param {*} obj
 * @param {*} properties
 */
export function deepGet (obj, properties) {
  if (obj === undefined || obj === null) {
    return
  }
  if (properties.length === 0) {
    return obj
  }
  var foundSoFar = obj[properties[0]]
  var remainingProperties = properties.slice(1)
  return deepGet(foundSoFar, remainingProperties)
}

export function encodeUtf8 (s) {
  return unescape(encodeURIComponent(s))
}

export function decodeUtf8 (s) {
  try {
    return decodeURIComponent(escape(s))
  } catch (er) {
    return s
  }
}

export const stripRenderedText = (fragment) => {
  const parser = new DOMParser()
  return parser.parseFromString(fragment, 'text/html').body.textContent
}

export function titleToUrlPart (s) {
  if (s === undefined) {
    return ''
  }
  let text = s.replace(/ /g, '-')
  text = accentsTidy(text).toLowerCase()
  text = text.replace(/(\w)\'/g, '$1')
    .replace(/[^a-z0-9_\-]+/g, '-') // Replace all non-word chars with -
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '')

  return text // Trim - from end of text
}
export function accentsTidy (s) {
  var r = s.toLowerCase()
  r = r.replace(new RegExp('[àáâãäå]', 'g'), 'a')
  r = r.replace(new RegExp('æ', 'g'), 'ae')
  r = r.replace(new RegExp('ç', 'g'), 'c')
  r = r.replace(new RegExp('[èéêë]', 'g'), 'e')
  r = r.replace(new RegExp('[ìíîï]', 'g'), 'i')
  r = r.replace(new RegExp('ñ', 'g'), 'n')
  r = r.replace(new RegExp('[òóôõö]', 'g'), 'o')
  r = r.replace(new RegExp('œ', 'g'), 'oe')
  r = r.replace(new RegExp('[ùúûü]', 'g'), 'u')
  r = r.replace(new RegExp('[ýÿ]', 'g'), 'y')
  return r
}

/**
 * recupera i commenti html presenti in un nodo
 * @param {HTMLElement} elem
 */
export function getComments (elem) {
  if (!Node) {
    // eslint-disable-next-line no-unused-vars
    const Node = {}
  }
  if (!Node.COMMENT_NODE) {
    // @ts-ignore
    Node.COMMENT_NODE = 8
  }
  const children = elem.childNodes
  const comments = []
  for (let i = 0, len = children.length; i < len; i++) {
    if (children[i].nodeType === Node.COMMENT_NODE) {
      comments.push(children[i])
    }
  }
  return comments
}
/**
 * Setta un cookie
 * @param {String} name : il nome del cookie
 * @param {*} value : il valore del cookie
 * @param {Number} days : per quanti giorni è valido il cookie
 * @param {String} path : il percorso per recuperare il cookie
 */
export const setCookie = (name, value, days = 7, path = '/') => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString()
  document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path
}
/**
 * recupera il valore di un cookie
 * @param {String} name : il nome del cookie
 */
export const getCookie = (name) => {
  return document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=')
    return parts[0] === name ? decodeURIComponent(parts[1]) : r
  }, '')
}
/**
 * Cancella un cookie
 * @param {*} name : il nome del cookie
 * @param {*} path : il percorso per recuperare il cookie
 */
export const deleteCookie = (name, path = '/') => {
  setCookie(name, '', -1, path)
}

export const diffDatesToSeconds = (startDate, endDate) => {
  return (endDate - startDate) / 1000
}

export const startTimeMarker = (key) => {
  // @ts-ignore
  if (window.ppmMarkers === undefined) {
    // @ts-ignore
    window.ppmMarkers = {}
  }
  // @ts-ignore
  window.ppmMarkers[key] = {
    start: Date.now(),
    end: null
  }
}
export const endTimeMarker = (key) => {
  // @ts-ignore
  if (window.ppmMarkers === undefined || window.ppmMarkers[key] === undefined) {
    return
  }

  // @ts-ignore
  window.ppmMarkers[key].end = Date.now()
  // @ts-ignore
  window.ppmMarkers[key].timing = diffDatesToSeconds(window.ppmMarkers[key].start, window.ppmMarkers[key].end)
}
/**
 * divide un array in sottogruppi
 * @param {*} a l'array
 * @param {*} n il numero dei chunk
 * @param {*} balanced se distribuire equamente
 * @returns array (of arrays)
 */
export function chunkifyArray (a, n, balanced = true) {
  if (n < 2) { return [a] }

  var len = a.length
  var out = []
  var i = 0
  var size

  if (len % n === 0) {
    size = Math.floor(len / n)
    while (i < len) {
      out.push(a.slice(i, i += size))
    }
  } else if (balanced) {
    while (i < len) {
      size = Math.ceil((len - i) / n--)
      out.push(a.slice(i, i += size))
    }
  } else {
    n--
    size = Math.floor(len / n)
    if (len % size === 0) { size-- }
    while (i < size * n) {
      out.push(a.slice(i, i += size))
    }
    out.push(a.slice(size * n))
  }

  return out
}

export function getCategories () {
  // @ts-ignore
  return JSON.parse(window.PageData.categories_menu)
}
export function getColors () {
  // @ts-ignore
  return JSON.parse(window.PageData.color_data)
}

export function isFrontPage () {
  // @ts-ignore
  return window.PageData.is_front_page === '1'
}

export function isCategory () {
  // @ts-ignore
  return window.PageData.is_category === '1'
}

export function getIntegrations () {
  // @ts-ignore
  return window.PageData.integrations
}
export function getPriorities () {
  try {
    // @ts-ignore
    const priorities = JSON.parse(PageData.homepage_priorities).filter(p => p.id !== '' && p.name !== '')
    if (priorities.length > 0) {
      return priorities
    }
    return false
  } catch (er) {
    return false
  }
}

export function getFeatures () {
  // @ts-ignore
  return window.PageData.features
}

export function getLogLevel () {
  // @ts-ignore
  return window.PageData.log_level
}

export function shouldShowAds () {
  const { ads } = getFeatures()
  return ads && getLogLevel() !== 'full_subscriber'
}

export function getServiceMenus () {
  // @ts-ignore
  return window.PageData.service_menus
}
