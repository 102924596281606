// @ts-check
const apiPath = 'wp-json/wp/v2/'

const BASE_URL = window.location.origin + '/'

export const EndPoints = {
  API_BASE: BASE_URL + apiPath,
  POSTS: BASE_URL + apiPath + 'posts',
  PAGES: BASE_URL + apiPath + 'pages',
  MEDIA: BASE_URL + apiPath + 'media',
  COMMENTS: BASE_URL + apiPath + 'comments',
  PRIORITIES_POSTS: BASE_URL + 'wp-json/ppm/v1/main',
  CATEGORIES: BASE_URL + apiPath + 'categories',
  SERVICE_MENUS: BASE_URL + 'wp-json/tm_routes/menu',
  NEXT_CONTENT: BASE_URL + 'wp-json/takerate/v1/nextcontent'
}
