/**
 * carica una immagine in modo asincrono
 * @param {img tag} img
 */
export async function loadImage (img) {
  const src = img.getAttribute('data-src')
  if (src) {
    return new Promise(function (resolve, reject) {
      try {
        // eslint-disable-next-line no-undef
        const image = new Image()

        image.addEventListener('load', onImageComplete)
        image.src = img.getAttribute('data-src')
        if (img.hasAttribute('data-srcset')) {
          image.srcset = img.getAttribute('data-srcset')
        }
        if (image.complete) {
          image.removeEventListener('load', onImageComplete)
          onImageComplete()
        }
        // eslint-disable-next-line no-inner-declarations
        function onImageComplete (e) {
          img.src = image.src
          if (img.hasAttribute('data-srcset')) {
            img.srcset = image.srcset
          }
          img.classList.remove('to-load')
          // img.classList.add('fade-in')
          resolve()
        }
      } catch (er) {
        reject(er)
      }
    })
  }
}
/**
 * carica tutte le immagini contenute in un elemento se flaggate con una selettore (default '.to-load')
 * @param {DOMNode} element : l'elemento in cui cercare le immagini
 * @param {*} selector : il selettore scelto per flaggare le immagini da caricare
 */
export async function loadContentImages (element, selector = '.to-load') {
  Array.from(element.querySelectorAll(selector)).forEach(function (img) {
    loadImage(img).then(() => {}).catch((er) => console.log('error loading image'))
  })
}
